import React, { useState, useEffect, useRef } from 'react'
import "../css/contact.css"
import { motion, useScroll, useTransform, AnimatePresence } from "framer-motion" 
import flag from "../assets/flag2.jpg"
import Footer from './Footer'

const Contact = () => {

const [ contact1, setContact1 ] = useState(true)
const [ contact2, setContact2 ] = useState(false)
const [ contact3, setContact3 ] = useState(false)

const contactRef1 = useRef()
const contactRef2 = useRef()
const contactRef3 = useRef()
const targetRef = useRef()

const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end start"]
})

const y = useTransform(scrollYProgress, [0, 1], [0, -200])
const whatY = useTransform(scrollYProgress, [0, 1], [0, 200])

const contactVar1 = {
  open: { height: 110, transition: { duration: 0.5 } },
  closed: { height: 0, transition: { duration: 0.5 } }
}

const contactVar2 = {
  open: { height: 170, transition: { duration: 0.5 } },
  closed: { height: 0, transition: { duration: 0.5 } }
}

const contactVar3 = {
  open: { height: 180, transition: { duration: 0.5 } },
  closed: { height: 0, transition: { duration: 0.5 } }
}

let title = {
  closed: { opacity: 1 },
  open: { opacity: 1, transition: { delayChildren: 1.2, staggerChildren: 0.1 }}
}

let letters = {
  closed: { y: "24vw" },
  open: { y: 0, transition: { duration: 1, ease: [0.5, 0.7, 0.05, 1] } }
}

useEffect(() => {
  let contact1Handler = (e) => { 
      if(!contactRef1.current.contains(e.target)){
          setContact1(false)
      }
  } 
  document.addEventListener("mousedown", contact1Handler)
}, [])

useEffect(() => {
  let contact2Handler = (e) => { 
      if(!contactRef2.current.contains(e.target)){
          setContact2(false)
      }
  } 
  document.addEventListener("mousedown", contact2Handler)
}, [])

useEffect(() => {
  let contact3Handler = (e) => { 
      if(!contactRef3.current.contains(e.target)){
          setContact3(false)
      }
  } 
  document.addEventListener("mousedown", contact3Handler)
}, [])


  return (
    <AnimatePresence mode={"wait"}>
    <div className="contact-grid">
      <div className="contact-header">
        <motion.img ref={targetRef} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.5, duration: 1 }}} style={{ y: whatY }} className="contact-header-img" src={flag} alt="" />
        <motion.div style={{ y }} variants={ title } initial="closed" animate="open" className="contact-text">
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>C</motion.div>
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>O</motion.div>
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>N</motion.div>
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>T</motion.div>
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>A</motion.div>
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>C</motion.div>
          <motion.div style={{ marginTop: '-2vw' }} variants={ letters }>T</motion.div>
        <div className="osix"><motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.1, duration: 1 }}}>0</motion.div><motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.3, duration: 1 }}}>6</motion.div></div>
        </motion.div>
      </div>
    
      <div className="contact-section">
        <div></div>
        <div className="contact-content">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.9, duration: 1.4 }}} className="contact-enquiries" ref={contactRef1} onClick={() => setContact1(true)}>
            <motion.div className='contact-buttons'>
              <div>ENQUIRIES</div>
              <div><svg width="35" height="35">
                        <line x1="33" y1="2" x2="2" y2="33" stroke="white" />
                        <line x1="33" y1="2" x2="33" y2="33" stroke="white" />
                        <line x1="2" y1="2" x2="33" y2="2" stroke="white" />
                    </svg>
              </div>
            </motion.div> 
            <motion.div variants={ contactVar1 } animate={ contact1 ? "open" : "closed" } className="contact-text1">
              <strong>General Enquiries</strong><br /><br />
              <div className="contact-email"><a whileHover={{ color: "#8349d3" }} href='mailto:contact@littleempiremusic.com' target="_blank">contact@littleempiremusic.com</a></div>
            </motion.div>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 3.1, duration: 1.4 }}} ref={contactRef2} onClick={() => setContact2(true)} className="contact-booking">
            <div className='contact-buttons'>
              <div>BOOKINGS</div>
              <div><svg width="35" height="35">
                        <line x1="33" y1="2" x2="2" y2="33" stroke="white" />
                        <line x1="33" y1="2" x2="33" y2="33" stroke="white" />
                        <line x1="2" y1="2" x2="33" y2="2" stroke="white" />
                    </svg>
              </div>
            </div>
            <motion.div variants={ contactVar2 } animate={ contact2 ? "open" : "closed" } className="contact-text2">
              <strong>Worldwide</strong><br /><br />
              William Morris Endeavor<br /><br />
              <div className="contact-email"><a href='mailto:Galantis@wmeagency.com' target="_blank">Galantis@wmeagency.com</a></div><br /><br />
              </motion.div>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 3.3, duration: 1.4 }}} onClick={() => setContact3(true)} className="contact-booking" ref={contactRef3}>
          <div className='contact-buttons'>
              <div>PRESS</div>
              <div><svg width="35" height="35">
                        <line x1="33" y1="2" x2="2" y2="33" stroke="white" />
                        <line x1="33" y1="2" x2="33" y2="33" stroke="white" />
                        <line x1="2" y1="2" x2="33" y2="2" stroke="white" />
                    </svg>
              </div>
              <motion.div variants={ contactVar3 } animate={ contact3 ? "open" : "closed" } className="contact-text3">
              <strong>Press Contact</strong><br /><br />
              Ted Sullivan<br />
              Atlantic Records<br /><br />
              <div className="contact-email"><a href='mailto:ted.sullivan@atlanticrecords.com' target="_blank">ted.sullivan@atlanticrecords.com</a></div>
            </motion.div>
            </div>
          </motion.div>
        </div>
        <div></div>
      </div>
    </div>
    <Footer />
    </AnimatePresence>
  )
}

export default Contact