import React, { useEffect, useState, useRef } from 'react'
import { motion, useScroll, useTransform, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import "../css/tour-section.css"
import Giglist from './Giglist'

const Tour = () => {

const [gig, setGig] = useState([])

const targetRef = useRef()

const [gigList, setGigList] = useState([]);

const fetchData = async () => {
try {
const response = await fetch('/.netlify/functions/tourdates'); 
// const response = await fetch('https://rest.bandsintown.com/artists/Galantis/events/?app_id=42f39cee7f4bda73a6b7dceb382bcc56'); 
if (!response.ok) {
throw new Error(`HTTP error! Status: ${response.status}`);
}
const data = await response.json();
setGigList(data);
} catch (error) {
console.error("Fetch error:", error);
}
};

useEffect(() => {
    fetchData()
}, [])

const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"]
})
let y = useTransform(scrollYProgress, [0, 1], ["-5vw", "-30vw"])

const { ref, inView } = useInView()
const animation = useAnimation()
const animation2 = useAnimation()
const animation3 = useAnimation()
const animation4 = useAnimation()


useEffect(() => {
    if(inView){
        animation.start({  y: '-1vw', transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 1, delay: 0.2 }})
        animation2.start({  y: '-1vw', transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 1, delay: 0.95 } })
        animation3.start({  y: '-1vw', transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 1, delay: 0.5 } })
        animation4.start({  y: '0vw', transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 1, delay: 0.85 } })

    }
    if(!inView){
        animation.start({ y: "20vw", transition: { ease: "easeInOut", duration: 0.5 } })
        animation2.start({ y: "20vw", transition: { ease: "easeInOut", duration: 0.5 } })
        animation3.start({ y: "20vw", transition: { ease: "easeInOut", duration: 0.5 } })
        animation4.start({ y: "6vw", transition: { ease: "easeInOut", duration: 0.5 } })
    }
}, [inView])

console.log(gigList.data)

  return (
    <>
    <motion.div className="tour-container">
        <div style={{ width: "100vw", height: "50vw", position: "absolute", pointerEvents: "none"}}></div>
        <motion.div style={{ y }} className="tour-title-container" ref={targetRef}>
            <motion.div className='tour'>
                    <motion.div style={{ marginTop: '-6vw' }} animate={ animation }>TOUR
                    </motion.div>
                </motion.div>
            <motion.div className='two'><motion.div animate={ animation2 }>02</motion.div></motion.div>
            <motion.div ref={ ref } className='dates'><motion.div style={{ marginTop: '-6vw' }} animate={ animation3 }>DATES</motion.div></motion.div>
            <motion.div className='tour-comment'><motion.div animate={ animation4 }>Click links for tickets</motion.div></motion.div>
        </motion.div>
        <div className="whenwherebuy"><div className="when">WHEN</div><div className="where">WHERE</div><div className="buy-title">BUY</div></div>
    <div className="giglist-container">
    {/* { gigList.map((gigs => { 
                return (
                    <Giglist 
                    key={gigs.venue.name}
                    country={gigs.venue.country}
                    city={gigs.venue.city}
                    date1={gigs.datetime?.slice(8, 10)}
                    date2={gigs.datetime?.slice(5, 7)}
                    event={gigs.venue.name}
                    tickets={gigs.url}
                    />  
                )}))
            } */}
        { gigList.data?.map((gigs) => { 
                return (
                    <Giglist  
                    key={gigs.attributes["venue-name"]} 
                    country={gigs.attributes["venue-country"]} 
                    city={gigs.attributes["venue-city"]} 
                    date1={gigs.attributes["starts-at-date-local"]?.slice(8, 10)} 
                    date3={gigs.attributes["ends-at-date-local"]?.slice(8, 10)} 
                    date2={gigs.attributes["starts-at-date-local"]?.slice(5, 7)} 
                    event={gigs.attributes["venue-name"]} 
                    tickets={gigs.attributes["primary-link-url"]} 
                    /> 
                )})
            }
    </div>
    </motion.div>
    
    </>
  )
}

export default Tour