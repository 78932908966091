import React, { useRef, useEffect, useState } from 'react'
import { AnimatePresence, motion, useScroll, useTransform } from 'framer-motion'
import galantisJumpTop from "../assets/galantisjumptop2.png"
import galantisJumpBottom from "../assets/galantisjumpbottom.jpg"
import "../css/about.css"
import { useInView } from 'react-intersection-observer'
import PharmacyText from './PharmacyText'
// import PbjText from './PbjText'
import ReleasesContainer from './ReleasesContainer'
import Footer from './Footer'
import delorian from "../assets/delorian1.jpg"
import PharmacyMid from "../assets/pharmacymid.jpg"
import AviaryMid from "../assets/aviarymid.jpg"
import ChurchMid from "../assets/churchmid.jpg"
import RunawayMid from "../assets/runawaymid.jpg"
import FaithMid from "../assets/faithmid.jpg"
import NoMoneyMid from "../assets/nomoneymid.jpg"
import DamnMid from "../assets/rxmid.jpg"
import HeartbreakMid from "../assets/heartbreakmid2.jpg"
import YouMid from "../assets/youmid.jpg"
import PBJMid from "../assets/pbjmid.jpg"

const About = ({ windowWidth }) => {

const targetRef = useRef()

const { ref: trigger1, inView: christian } = useInView()
const { ref: trigger2, inView: billions } = useInView()
const { ref: trigger3, inView: pharmacy } = useInView()
const { ref: trigger32, inView: pharmacyt2 } = useInView()
const { ref: trigger33, inView: pharmacyt3 } = useInView()
const { ref: trigger34, inView: pharmacyt4 } = useInView()
const { ref: trigger35, inView: pharmacyt5 } = useInView()
const { ref: trigger36, inView: pharmacyt6 } = useInView()
const { ref: trigger362, inView: pharmacyt62 } = useInView()
const { ref: trigger37, inView: pharmacyt7 } = useInView()


let title = {
  closed: { opacity: 1 },
  open: { opacity: 1, transition: { delayChildren: 1.2, staggerChildren: 0.1 }}
}

let letters = {
  closed: { y: "24vw" },
  open: { y: 0, transition: { duration: 1, ease: [0.5, 0.7, 0.05, 1] } }
}

const { scrollYProgress } = useScroll({
  target: targetRef,
  offset: ["start start", "end start"]
})



const about1 = { open: { opacity: 1, transition: { duration: 1.4, delay: 1 }}, closed: { opacity: 0, transition: { duration: 1 } } }
const scaling1 = { open: { scale: 1.35, transition: { duration: 1.4 }}, closed: { scale: 1, transition: { duration: 1, delay: 0.2 } } }
const scaling2 = { open: { scale: 1.35, x: "-15vw", transition: { duration: 1.4 }}, closed: { scale: 1, x: "0vw", transition: { duration: 1, delay: 0.2 } } }
const christian1 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1.1 } }, closed: { opacity: 0, y: 10  } }
const christian2 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1.2 } }, closed: { opacity: 0, y: 10  } }
const christian3 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1.3 } }, closed: { opacity: 0, y: 10  } }
const christian4 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1.4 } }, closed: { opacity: 0, y: 10  } }
const christian5 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1.5 } }, closed: { opacity: 0, y: 10  } }
const christian6 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1.6 } }, closed: { opacity: 0, y: 10  } }
const billions0 = { open: { opacity: 1, transition: { duration: 0.5, delay: 0.5 } }, closed: { opacity: 0, transition: { duration: 0.5 }}}
const billions1 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.6 } }, closed: { opacity: 0, y: 10 } }
const billions2 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.7 } }, closed: { opacity: 0, y: 10 } }
const billions3 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.8 } }, closed: { opacity: 0, y: 10 } }
const billions4 = { open: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.9 } }, closed: { opacity: 0, y: 10 } }
const billions5 = { open: { opacity: 1, transition: { duration: 0.5 }}, closed: { opacity: 0, transition: { duration: 0.7 }} }
const pharmacy1 = { open: { height: "100vh", transition: { duration: 1.2 } }, closed: { height: "0vh", transition: { duration: 0.8, ease: "linear" }}}
const pharmacy2 = { open: { y: -100, transition: { duration: 1.2 } }, closed: { y: 0, transition: { duration: 0.8 }}}
const pharmacy3 = { open: { y: -180, transition: { duration: 1.2 } }, closed: { y: 0, transition: { duration: 0.8 }}}
const pharmacyText2 = { open: { opacity: 1, transition: { duration: 1.2 } }, closed: { opacity: 0, transition: { duration: 0.8 }}}
const textShift = { open: { y: "-120vh", transition: { duration: 2, delay: 0.3, ease: [0.5, 0.7, 0.05, 0.9] }}, closed: {y: 0, transition: { duration: 2, ease: [0.5, 0.7, 0.05, 0.9] }} }
const pharmacyInitialText = { open: { opacity: 1, y: 0, transition: { duration: 1.4, delay: 1.5, ease: [0.5, 0.7, 0.05, 0.9] } }, closed: { opacity: 0, y: 500, transition: { duration: 0.8, ease: [0.5, 0.7, 0.05, 0.9] }}}
const history1 = { open: { height: "100vh", transition: { duration: 1.2, delay: 1.2, ease: [0.5, 0.7, 0.05, 0.9] } }, closed: { height: "0", transition: { duration: 0.8, ease: "linear" }}}
const history2 = { open: { height: "100vh", transition: { duration: 1.2, delay: 2.2, ease: [0.5, 0.7, 0.05, 0.9] } }, closed: { height: "0", transition: { duration: 0.8, ease: "linear" }}}
const history3 = { open: { opacity: 1, transition: { duration: 1, delay: 3.5, ease: [0.5, 0.7, 0.05, 0.9] } }, closed: { opacity: 0, transition: { duration: 0.3, ease: "linear" }}}
const history4 = { open: { opacity: 1, transition: { duration: 1, delay: 3.7, ease: [0.5, 0.7, 0.05, 0.9] } }, closed: { opacity: 0, transition: { duration: 0.3, ease: "linear" }}}
const y = useTransform(scrollYProgress, [0.6, 1], [0, -400])
const y2 = useTransform(scrollYProgress, [0.6, 1], [0, -600])


  return (
    <motion.div className="about-container" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1 } }} ref={targetRef} style={{ position: "relative" }}>
      <div style={{ position: "relative", height: 1, width: "100%" }}></div>
      <div className='about-header'>
        <motion.div className="about-header-container" variants={ windowWidth > 1000 ? scaling2 : scaling1 } animate={ christian ? "open" : "closed" }>
        <motion.div variants={ pharmacy2 } animate={ pharmacy ? "open" : "closed" } style={{ height: "100%", width: "100%", position: "fixed", top: 0}}>
          <motion.img initial={{ opacity: 0, scale: 1.05 }} animate={{ opacity: 0.7, scale: 1, transition: { delay: 2.5, duration: 2 }}} ref={targetRef} src={galantisJumpBottom} width="100%" height="100%" style={{ objectFit: "cover", position: "absolute", top: 0 }} />
        </motion.div> 
        <motion.div className="galantis-jump-surround" variants={ pharmacy3 } animate={ pharmacy ? "open" : "closed" }>
          <motion.img className='galantis-jump-top' initial={{ opacity: 0, y: 5 }} animate={{ opacity: 1, y: 0, transition: { delay: 2.5, duration: 1.4 }}} src={galantisJumpTop} width="100%" height="100%" /> 
        </motion.div>
          <motion.div className="about-surround" variants={ about1 } animate={ christian ? "closed" : "open" }>
            <motion.div className="three">
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.2, duration: 0.5 }}}>0</motion.div> 
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.4, duration: 0.5 }}}>3</motion.div>
            </motion.div>
            <motion.div variants={ title } initial="closed" animate="open" className="about-title">
                <motion.div style={{ marginTop: '-6vw' }} variants={ letters }>A</motion.div>
                <motion.div style={{ marginTop: '-6vw' }} variants={ letters }>B</motion.div>
                <motion.div style={{ marginTop: '-6vw' }} variants={ letters }>O</motion.div>
                <motion.div style={{ marginTop: '-6vw' }} variants={ letters }>U</motion.div>
                <motion.div style={{ marginTop: '-6vw' }} variants={ letters }>T</motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
          <motion.div variants={ billions0 } animate={ billions ? "closed" : "open" } className="biog1">
            <div>
              <motion.div variants={ christian1 } animate={ christian ? "open" : "closed" } style={{ height: 0 }}>Galantis is the dance</motion.div>
              <br /> 
              <motion.div variants={ christian2 } animate={ christian ? "open" : "closed" } style={{ height: 20 }}>music project of</motion.div>
              <div className="ck">
                <motion.div variants={ christian3 } animate={ christian ? "open" : "closed" } style={{ height: 0 }}>CHRISTIAN</motion.div>
                <br />
                <motion.div variants={ christian4 } animate={ christian ? "open" : "closed" }>KARLSSON</motion.div>
              </div>
              <motion.div variants={ christian5 } animate={ christian ? "open" : "closed" } style={{  height: 0 }}>
              (aka Bloodshy)
              </motion.div>
              <br />
              <motion.div variants={ christian6 } animate={ christian ? "open" : "closed" }>that has received...</motion.div>
            </div>
          </motion.div>
      </div>
      <motion.div variants={ billions5 } animate={ billions ? "open" : "closed"} className="billions-backdrop"></motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 3 } }} className="billions">
        <div style={{ height: "300px", alignSelf: "end", display: "grid", alignItems: "center"}}>
          <div className="biog2">
            <motion.div variants={ billions1 } animate={ billions ? "open" : "closed" } className='ck' style={{ marginBottom: "25px"}}>7.3 BILLION+</motion.div>
            <motion.div variants={ billions2 } animate={ billions ? "open" : "closed" }>worldwide streams</motion.div>
          </div>
        </div>
        <div style={{ height: "300px", alignSelf: "start", display: "grid", alignItems: "center"}}>
          <div className="biog2">
            <motion.div variants={ billions3 } animate={ billions ? "open" : "closed" } className='ck' style={{ marginBottom: "25px" }}>1.9 BILLION+</motion.div>
            <motion.div variants={ billions4 } animate={ billions ? "open" : "closed" }>video views</motion.div>
          </div>
        </div>
      </motion.div>

      <motion.div variants={ pharmacy1 } animate={ pharmacy ? "open" : "closed"} className="pharmacy-bg" style={{ bottom: 0, position: "fixed", backgroundColor: "#090909", zIndex: 500 }}></motion.div>
      <motion.div style={{ bottom: 0, position: "fixed", height: "100vh", width: "100vw", zIndex: 700 }}>
      <motion.div className="pharmacy-text-box" variants={ pharmacyInitialText } animate={ pharmacy ? "open" : "closed" } style={{ position: "relative", fontSize: "clamp( 0.7rem, 1vw, 1.2rem )"}}>
      <motion.div className="pharmacy-grid-text" variants={ pharmacyText2 } animate={ pharmacy ? "open" : "closed" }>
              <motion.div variants={ textShift } animate={ pharmacyt7 ? "open" : "closed" }>
              <div className="subtitle">RELEASES</div>
              <br /><br />
              <AnimatePresence>
                { pharmacyt2 && !pharmacyt4 && 
                <PharmacyText text="RIAA gold-certified debut album Pharmacy reached No. 1 on the Billboard Dance album chart on the strength of platinum-certified single 'Peanut Butter Jelly' and 3x platinum certified smash 'Runaway (U & I)' – the latter of which earned a GRAMMY nomination for 'Best Dance Recording'" />
                }
              </AnimatePresence>
              <AnimatePresence>
                { pharmacyt4 && !pharmacyt5 &&
                <PharmacyText text="while 2017's sophomore album The Aviary (featuring 2x platinum-certified single 'No Money') saw a top 10 debut in 23 countries." />  
                }
              </AnimatePresence>
              <AnimatePresence>
                { pharmacyt5 && !pharmacyt6 &&
                <PharmacyText text="The third Galantis album, 2020's Church, featured the chart-topping gold-certified single 'Faith' with country music icon Dolly Parton featuring Mr. Probz, which saw a #1 debut on Billboard's Dance/Electronic Chart before quickly reaching #1 on the Dance/Mix Show Airplay Chart where it remained for 5 weeks." />  
                }
              </AnimatePresence>
              <AnimatePresence>
                { pharmacyt6 && !pharmacyt62 &&
                <PharmacyText text="Meanwhile, gold-certified collaborative single 'Heartbreak Anthem' with David Guetta & Little Mix proved a global sensation, spending 10 weeks on the Billboard Global 200 chart and 13 consecutive weeks in the top 10 on the UK Official Chart." />  
                }
              </AnimatePresence>
              <AnimatePresence>
                { pharmacyt62 && 
                <PharmacyText text="Latest album, Rx, is Karlsson's most deeply personal creation to date – featuring pop banger 'Lighter' with Guetta and 5 Seconds of Summer which landed No.1 on the US Dance Radio chart." />  
                }
              </AnimatePresence>
              </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      { pharmacy &&
      <AnimatePresence> 
        
      <motion.div variants={ pharmacyInitialText } animate={ pharmacy ? "open" : "closed" } className="pharmacy-grid" style={{ height: "100vh", width: "100vw", position: "fixed", top: 0, display: "grid", zIndex: 600 }}>
        <motion.div style={{ width: "100vw", height: "100vh", top: "0", position: "fixed" }}>
            <ReleasesContainer PharmacyMid={ PharmacyMid } PBJMid={ PBJMid } YouMid={ YouMid } HeartbreakMid={ HeartbreakMid } DamnMid={DamnMid} NoMoneyMid={ NoMoneyMid } AviaryMid={ AviaryMid } FaithMid={FaithMid} RunawayMid={ RunawayMid } ChurchMid={ ChurchMid } windowWidth={ windowWidth } pharmacyt2={ pharmacyt2 } pharmacyt4={ pharmacyt4 } pharmacyt5={ pharmacyt5 } pharmacyt6={ pharmacyt6 } pharmacyt62={ pharmacyt62 } pharmacyt7={ pharmacyt7 } />
        </motion.div>
        <motion.div className="pharmacy-grid-text" variants={ pharmacyText2 } animate={ pharmacy ? "open" : "closed" }>
          <motion.div variants={ textShift } animate={ pharmacyt7 ? "open" : "closed" } >
           



        </motion.div>
      </motion.div>
    

      <motion.div variants={ history1 } animate={ pharmacyt7 ? "open" : "closed" } className="history">
        <div style={ windowWidth > 1000 ? { position: "absolute", height: "100vh", width: "100vw" } : { position: "absolute", height: "100vh", width: "140vw" } }>
        <motion.img className="delorian-img" src={ delorian } style={{ objectFit: "cover", width: "110%", height: "110%", position: "absolute", y }} />
        </div>
          <motion.div variants={ history2 } animate={ pharmacyt7 ? "open" : "closed" } className="history2" style={{ overflow: "hidden", display: "grid" }}> 
            <motion.div style={{position: "fixed", width: "100%", height: "100%", zIndex: 802, top: 0, display: "flex", alignItems: "center"}}>
              <motion.div className="history3" style={{ padding: "2rem", y: y2 }}>
                <motion.span variants={ history3 } animate={ pharmacyt7 ? "open" : "closed" } initial="closed" className="subtitle" style={{ zIndex: 802 }}>HISTORY</motion.span>
                <br /><br />
                <motion.div style={{ fontSize: "clamp(0.7rem, 1vw, 1rem)" }} variants={ history4 } animate={ pharmacyt7 ? "open" : "closed" } initial="closed">
                Karlsson has been at the helm of Galantis since 2009 before officially debuting the project at 2014's Coachella Valley Music and Arts Festival. His career has spanned across the musical genres of punk-rock, hip-hop and pop – including work as one half of acclaimed production duo Bloodshy & Avant and one third of indie pop band Miike Snow. Karlsson's songwriting/production credits have included the likes of Madonna, Kylie Minogue, Katy Perry and extensive work with Britney Spears (including "Toxic" which earned a GRAMMY for "Best Dance Recording" and her widely acclaimed 2007 album Blackout).<br /><br />
                As Galantis, he has crafted remixes for the likes of Coldplay, Selena Gomez, Ed Sheeran, Sam Smith & more. Declared "pop's mastermind producer" by UPROXX, "eternally effervescent" by Billboard and "one of the most influential and untamed voices in modern music" by GRAMMY.com, the Swedish-born artist continues to evolve and push the boundaries of songwriting within the world of dance music and beyond.

                </motion.div>
              </motion.div>
            </motion.div>

          </motion.div>
      </motion.div>
        
      </motion.div>
      </AnimatePresence>
      }
      <div style={{ height: "5vh" }}></div>
      <div ref={ trigger1 } style={{ height: "50vh" }}></div>
      <div ref={ trigger2 } style={{ height: "50vh" }}></div>
      <div ref={ trigger3 } style={{ zIndex: 700, position: "relative" }}>
        <div ref={ trigger32 } style={{ height: "10vh" }}></div>
        <div ref={ trigger33 } style={{ height: "50vh" }}></div>
        <div ref={ trigger34 } style={{ height: "50vh" }}></div>
        <div ref={ trigger35 } style={{ height: "50vh" }}></div>
        <div ref={ trigger36 } style={{ height: "50vh" }}></div>
        <div ref={ trigger362 } style={{ height: "50vh" }}></div>
        <div ref={ trigger37 } style={{ paddingTop: "50vh" }}>
        <Footer />
        </div>
        
      </div>
    </motion.div>
  )
}

export default About

